import React, { useMemo, useState } from 'react';

import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { DateTimeFormatter, LocalDate } from '@js-joda/core';

import { useQuery } from '@tanstack/react-query';

import { publish } from '~/utils/event';

import { axiosInstance as axios } from '~/axios';

import { dailyFetcher } from './DashboardFetchers';
import { IDashboardDailyData } from './models';
import { clsx } from 'clsx';

const DailyPortlet = (props: { tenantId: string } & React.HTMLAttributes<HTMLDivElement>) => {
  const { tenantId, className } = props;
  const [workDay, setWorkDay] = useState<LocalDate>(LocalDate.now());

  const { _ } = useLingui();
  const { data, isPending, isError } = useQuery({
    queryKey: ['dashboard-daily', tenantId, workDay],
    queryFn: ({ signal }) => dailyFetcher(axios, tenantId, workDay, signal),
    enabled: tenantId != null && workDay != null,
    select: data =>
      window.settings.tenant.showOutsource == false
        ? data.data?.filter((f: { sectionName: string }) => f.sectionName != '外注委託')
        : data,
  });

  const sumOfContractNum = useMemo(() => {
    if (data != null && data?.status == 200 && data?.data != null) {
      return data?.data?.reduce((acc: number, cur: IDashboardDailyData) => acc + cur.numOfContract, 0) ?? 0;
    }

    return 0;
  }, [data]);

  const sumOfBuildingNum = useMemo(() => {
    if (data != null && data?.status == 200 && data?.data != null) {
      return data?.data?.reduce((acc: number, cur: IDashboardDailyData) => acc + cur.numOfBuilding, 0) ?? 0;
    }

    return 0;
  }, [data]);

  const sumOfTeamNum = useMemo(() => {
    if (data != null && data?.status == 200 && data?.data != null) {
      return data?.data?.reduce((acc: number, cur: IDashboardDailyData) => acc + cur.numOfTeam, 0) ?? 0;
    }

    return 0;
  }, [data]);

  const sumOfAmount = useMemo(() => {
    if (data != null && data?.status == 200 && data?.data != null) {
      return data?.data?.reduce((acc: number, cur: IDashboardDailyData) => acc + cur.amountSum, 0) ?? 0;
    }

    return 0;
  }, [data]);

  return (
    <div className={className}>
      <div className="mx-2 mt-2 rounded-t-lg bg-[#4372C4] pl-4 font-anton text-[12pt] font-semibold text-white">
        <Trans>Daily Report</Trans>
      </div>
      <div className="mx-2 flex flex-col items-center justify-center rounded-b-lg bg-white px-0 py-2 font-body">
        <div className="flex flex-row items-center justify-center gap-4">
          <div
            className="cursor-pointer select-none text-[12pt] text-gray-500"
            onClick={() => {
              publish('soundButtonClicked');
              setWorkDay(workDay.minusDays(1));
            }}>
            ◀︎
          </div>
          <div className="select-none text-[10.5pt] text-gray-700">
            {workDay.format(DateTimeFormatter.ofPattern('yyyy年MM月dd日'))}
          </div>
          <div
            className="cursor-pointer select-none text-[12pt] text-gray-500"
            onClick={() => {
              publish('soundButtonClicked');
              setWorkDay(workDay.plusDays(1));
            }}>
            ▶︎
          </div>
        </div>
        {isPending ? null : isError ? (
          <div className="text-red-900"></div>
        ) : (
          <table>
            <thead>
              <tr>
                <th className="w-[6rem] text-[9pt] text-blue-500">&nbsp;</th>
                <th colSpan={4} className="text-blue-500">
                  <div className="rounded-md bg-[#B4C7E7] text-center text-[9pt] text-white">
                    <Trans>予 定</Trans>
                  </div>
                </th>
              </tr>
              <tr>
                <th className="w-[6rem] py-1 text-right text-[9pt] text-blue-500">&nbsp;</th>
                <th className="w-16 py-1 text-right text-[9pt] text-blue-500">
                  <Trans>契約数</Trans>
                </th>
                <th className="w-16 py-1 text-right text-[9pt] text-blue-500">
                  <Trans>物件数</Trans>
                </th>
                <th className="w-16 py-1 text-right text-[9pt] text-blue-500">
                  <Trans>ﾁｰﾑ数</Trans>
                </th>
                <th
                  className={clsx('w-16 py-1 text-right text-[9pt] text-blue-500', {
                    hidden: !window.settings.tenant.showCurrency || !window.permissions.includes('p_currency'),
                  })}>
                  {window.settings.tenant.showCurrency && window.permissions.includes('p_currency') ? (
                    <Trans>金額計</Trans>
                  ) : null}
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.data == null || data?.data?.length === 0 ? (
                <tr>
                  <td colSpan={3} className="text-[9pt]">
                    <Trans>データがありません</Trans>
                  </td>
                </tr>
              ) : (
                data?.data?.map((d: IDashboardDailyData, index: number) => (
                  <tr key={index}>
                    <td
                      className="accounting-numeric p-1 text-right font-body text-[9pt]"
                      style={
                        d.sectionColor != 'OOUTSOURCE'
                          ? { color: d.sectionColor }
                          : { color: window.settings.tenant.outsourceColor }
                      }>
                      {d.sectionName} :{' '}
                    </td>
                    <td
                      className="accounting-numeric p-1 text-right font-body text-[9pt]"
                      style={
                        d.sectionColor != 'OOUTSOURCE'
                          ? { color: d.sectionColor }
                          : { color: window.settings.tenant.outsourceColor }
                      }>
                      {d.numOfContract.toLocaleString('ja-JP', {
                        style: 'decimal',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                      <Trans>件</Trans>
                    </td>
                    <td
                      className="accounting-numeric p-1 text-right font-body text-[9pt]"
                      style={
                        d.sectionColor != 'OOUTSOURCE'
                          ? { color: d.sectionColor }
                          : { color: window.settings.tenant.outsourceColor }
                      }>
                      {d.numOfBuilding.toLocaleString('ja-JP', {
                        style: 'decimal',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                      <Trans>件</Trans>
                    </td>
                    <td
                      className="accounting-numeric p-1 text-right font-body text-[9pt]"
                      style={
                        d.sectionColor != 'OOUTSOURCE'
                          ? { color: d.sectionColor }
                          : { color: window.settings.tenant.outsourceColor }
                      }>
                      {d.numOfTeam.toLocaleString('ja-JP', {
                        style: 'decimal',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </td>
                    <td
                      className={clsx('accounting-numeric p-1 text-right font-body text-[9pt]', {
                        hidden: !window.settings.tenant.showCurrency || !window.permissions.includes('p_currency'),
                      })}
                      style={
                        d.sectionColor != 'OOUTSOURCE'
                          ? { color: d.sectionColor }
                          : { color: window.settings.tenant.outsourceColor }
                      }>
                      {window.settings.tenant.showCurrency && window.permissions.includes('p_currency')
                        ? d.amountSum.toLocaleString('ja-JP', {
                            style: 'currency',
                            currency: 'JPY',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : null}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={9}>
                  <hr className="my-0.5 h-px border-0 bg-gray-900" />
                </td>
              </tr>
              <tr>
                <td className="accounting-numeric p-1 text-right font-body text-[9pt]">合計 :</td>
                <td className="accounting-numeric p-1 text-right font-body text-[9pt]">
                  {sumOfContractNum.toLocaleString('ja-JP', {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  <Trans>件</Trans>
                </td>
                <td className="accounting-numeric p-1 text-right font-body text-[9pt]">
                  {sumOfBuildingNum.toLocaleString('ja-JP', {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  <Trans>件</Trans>
                </td>
                <td className="accounting-numeric p-1 text-right font-body text-[9pt]">
                  {sumOfTeamNum.toLocaleString('ja-JP', {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td
                  className={clsx('accounting-numeric p-1 text-right font-body text-[9pt]', {
                    hidden: !window.settings.tenant.showCurrency || !window.permissions.includes('p_currency'),
                  })}>
                  {window.settings.tenant.showCurrency && window.permissions.includes('p_currency')
                    ? sumOfAmount.toLocaleString('ja-JP', {
                        style: 'currency',
                        currency: 'JPY',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : null}
                </td>
              </tr>
            </tfoot>
          </table>
        )}
      </div>
    </div>
  );
};

export default DailyPortlet;
