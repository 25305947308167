import React, { FC } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

import Page from '~/Page';

import { ApplicationRoot } from '~/components/common/providers';

import '@mantine/core/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/nprogress/styles.css';
import '@mantine/notifications/styles.css';
import WebVitals from './features/WebVitals';

export const ErrorFallback: FC<FallbackProps> = ({ error, resetErrorBoundary }) => (
  <div>
    <p>エラーが発生しました</p>
    <p>{error.message}</p>
    <button onClick={resetErrorBoundary}>再実行</button>
  </div>
);

const App = () => (
  <React.Suspense fallback={<div>Loading...</div>}>
    {/*<ErrorBoundary FallbackComponent={ErrorFallback}>*/}
    <ApplicationRoot>
      <Page />
      <WebVitals />
    </ApplicationRoot>
    {/*</ErrorBoundary>*/}
  </React.Suspense>
);
export default App;
