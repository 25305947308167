import React, { useMemo, useState } from 'react';

import { Trans } from '@lingui/macro';

import { DateTimeFormatter, YearMonth } from '@js-joda/core';

import { useQuery } from '@tanstack/react-query';

import { publish } from '~/utils/event';

import { axiosInstance as axios } from '~/axios';

import { monthlyFetcher } from './DashboardFetchers';
import { IDashboardMonthlyData } from './models';
import { clsx } from 'clsx';

const MonthlyPortlet = (props: { tenantId: string } & React.HTMLAttributes<HTMLDivElement>) => {
  const { tenantId, className } = props;
  const [targetYearmonth, setTargetYearmonth] = useState<YearMonth>(YearMonth.now());

  const { data, isPending, isError } = useQuery({
    queryKey: ['dashboard-monthly', tenantId, targetYearmonth],
    queryFn: ({ signal }) =>
      monthlyFetcher(axios, tenantId, targetYearmonth.format(DateTimeFormatter.ofPattern('yyyyMM')), signal),
    enabled: tenantId != null && targetYearmonth != null,
    select: data =>
      window.settings.tenant.showOutsource == false
        ? data.data?.filter((f: { sectionName: string }) => f.sectionName != '外注委託')
        : data,
  });

  const forecastSumOfContractNum = useMemo(() => {
    if (data != null && data?.status == 200 && data?.data != null) {
      return data?.data?.reduce((acc: number, cur: IDashboardMonthlyData) => acc + cur.forecastNumOfContract, 0) ?? 0;
    }

    return 0;
  }, [data]);

  const forecastSumOfBuildingNum = useMemo(() => {
    if (data != null && data?.status == 200 && data?.data != null) {
      return data?.data?.reduce((acc: number, cur: IDashboardMonthlyData) => acc + cur.forecastNumOfBuilding, 0) ?? 0;
    }

    return 0;
  }, [data]);

  const forecastSumOfAmount = useMemo(() => {
    if (data != null && data?.status == 200 && data?.data != null) {
      return (data?.data?.reduce((acc: number, cur: IDashboardMonthlyData) => acc + cur.forecastAmountSum, 0) ??
        0) as number;
    }

    return 0;
  }, [data]);
  const actualSumOfContractNum = useMemo(() => {
    if (data != null && data?.status == 200 && data?.data != null) {
      return (data?.data?.reduce((acc: number, cur: IDashboardMonthlyData) => acc + cur.actualNumOfContract, 0) ??
        0) as number;
    }

    return 0;
  }, [data]);

  const actualSumOfBuildingNum = useMemo(() => {
    if (data != null && data?.status == 200 && data?.data != null) {
      return (data?.data?.reduce((acc: number, cur: IDashboardMonthlyData) => acc + cur.actualNumOfBuilding, 0) ??
        0) as number;
    }

    return 0;
  }, [data]);

  const actualSumOfAmount = useMemo(() => {
    if (data != null && data?.status == 200 && data?.data != null) {
      return (data?.data?.reduce((acc: number, cur: IDashboardMonthlyData) => acc + cur.actualAmountSum, 0) ??
        0) as number;
    }

    return 0;
  }, [data]);

  const forecastRate = useMemo(
    () => (forecastSumOfAmount > 0 ? (actualSumOfAmount / forecastSumOfAmount) * 100 : 0),
    [data]
  );

  return (
    <div className={className}>
      <div className="mx-2 mt-2 rounded-t-lg bg-[#4372C4] pl-4 font-anton text-[12pt] font-semibold text-white">
        <Trans>Monthly Report</Trans>
      </div>
      <div className="mx-2 flex flex-col items-center justify-center rounded-b-lg bg-white p-2 font-body">
        <div className="flex flex-row items-center justify-center gap-4">
          <div
            className="cursor-pointer select-none text-[12pt] text-gray-500"
            onClick={() => {
              publish('soundButtonClicked');
              setTargetYearmonth(targetYearmonth.minusMonths(1));
            }}>
            ◀︎
          </div>
          <div className="select-none text-[10.5pt] text-gray-700">
            {targetYearmonth.format(DateTimeFormatter.ofPattern('yyyy年MM月'))} 作業計画
          </div>
          <div
            className="cursor-pointer select-none text-[12pt] text-gray-500"
            onClick={() => {
              publish('soundButtonClicked');
              setTargetYearmonth(targetYearmonth.plusMonths(1));
            }}>
            ▶︎
          </div>
        </div>
        {isPending ? null : isError ? (
          <div className="text-red-900"></div>
        ) : (
          <table>
            <thead>
              <tr>
                <th className="text-[9pt] text-blue-500">&nbsp;</th>
                <th
                  colSpan={window.settings.tenant.showCurrency && window.permissions.includes('p_currency') ? 3 : 2}
                  className="text-blue-500">
                  <div className="rounded-md bg-[#B4C7E7] text-center text-[9pt] text-white">予 定</div>
                </th>
                <th className="w-2">&nbsp;</th>
                <th
                  colSpan={window.settings.tenant.showCurrency && window.permissions.includes('p_currency') ? 4 : 3}
                  className="text-blue-500">
                  <div className="rounded-md bg-[#F8CBAD] text-center text-[9pt] text-white">実 績</div>
                </th>
              </tr>
              <tr>
                <th className="w-[6rem] text-[9pt] text-blue-500">&nbsp;</th>
                <th className="w-16 text-right text-[9pt] text-blue-500">
                  <Trans>契約数</Trans>
                </th>
                <th className="w-16 text-right text-[9pt] text-blue-500">
                  <Trans>物件数</Trans>
                </th>
                <th
                  className={clsx('w-16 text-right text-[9pt] text-blue-500', {
                    hidden: !window.settings.tenant.showCurrency || !window.permissions.includes('p_currency'),
                  })}>
                  {window.settings.tenant.showCurrency && window.permissions.includes('p_currency') ? (
                    <Trans>金額計</Trans>
                  ) : null}
                </th>
                <th className="w-2">&nbsp;</th>
                <th className="w-16 text-right text-[9pt] text-blue-500">
                  <Trans>契約数</Trans>
                </th>
                <th className="w-16 text-right text-[9pt] text-blue-500">
                  <Trans>物件数</Trans>
                </th>
                <th
                  className={clsx('w-16 text-right text-[9pt] text-blue-500', {
                    hidden: !window.settings.tenant.showCurrency || !window.permissions.includes('p_currency'),
                  })}>
                  {window.settings.tenant.showCurrency && window.permissions.includes('p_currency') ? (
                    <Trans>金額計</Trans>
                  ) : null}
                </th>
                <th className="w-16 text-right text-[9pt] text-blue-500">
                  <Trans>達成率</Trans>
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.data == null || data?.data?.length === 0 ? (
                <tr>
                  <td colSpan={3} className="text-[9pt]">
                    <Trans>データがありません</Trans>
                  </td>
                </tr>
              ) : (
                data?.data?.map((d: IDashboardMonthlyData, index: number) => (
                  <tr key={index}>
                    <td
                      className="p-1 text-right text-[9pt]"
                      style={
                        d.sectionColor != 'OOUTSOURCE'
                          ? { color: d.sectionColor }
                          : { color: window.settings.tenant.outsourceColor }
                      }>
                      {d.sectionName} :{' '}
                    </td>
                    <td
                      className="accounting-numeric p-1 text-right font-body text-[9pt]"
                      style={{ color: d.sectionColor }}>
                      {d.forecastNumOfContract.toLocaleString('ja-JP', {
                        style: 'decimal',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                      件
                    </td>
                    <td
                      className="accounting-numeric p-1 text-right font-body text-[9pt]"
                      style={
                        d.sectionColor != 'OOUTSOURCE'
                          ? { color: d.sectionColor }
                          : { color: window.settings.tenant.outsourceColor }
                      }>
                      {d.forecastNumOfBuilding.toLocaleString('ja-JP', {
                        style: 'decimal',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                      件
                    </td>
                    <td
                      className={clsx('accounting-numeric p-1 text-right font-body text-[9pt]', {
                        hidden: !window.settings.tenant.showCurrency || !window.permissions.includes('p_currency'),
                      })}
                      style={
                        d.sectionColor != 'OOUTSOURCE'
                          ? { color: d.sectionColor }
                          : { color: window.settings.tenant.outsourceColor }
                      }>
                      {window.settings.tenant.showCurrency && window.permissions.includes('p_currency')
                        ? d.forecastAmountSum.toLocaleString('ja-JP', {
                            style: 'currency',
                            currency: 'JPY',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : null}
                    </td>
                    <td>&nbsp;</td>
                    <td
                      className="accounting-numeric p-1 text-right font-body text-[9pt]"
                      style={
                        d.sectionColor != 'OOUTSOURCE'
                          ? { color: d.sectionColor }
                          : { color: window.settings.tenant.outsourceColor }
                      }>
                      {d.actualNumOfContract.toLocaleString('ja-JP', {
                        style: 'decimal',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                      件
                    </td>
                    <td
                      className="accounting-numeric p-1 text-right font-body text-[9pt]"
                      style={
                        d.sectionColor != 'OOUTSOURCE'
                          ? { color: d.sectionColor }
                          : { color: window.settings.tenant.outsourceColor }
                      }>
                      {d.actualNumOfBuilding.toLocaleString('ja-JP', {
                        style: 'decimal',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                      件
                    </td>
                    <td
                      className={clsx('accounting-numeric p-1 text-right font-body text-[9pt]', {
                        hidden: !window.settings.tenant.showCurrency || !window.permissions.includes('p_currency'),
                      })}
                      style={
                        d.sectionColor != 'OOUTSOURCE'
                          ? { color: d.sectionColor }
                          : { color: window.settings.tenant.outsourceColor }
                      }>
                      {window.settings.tenant.showCurrency && window.permissions.includes('p_currency')
                        ? d.actualAmountSum.toLocaleString('ja-JP', {
                            style: 'currency',
                            currency: 'JPY',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : null}
                    </td>
                    <td
                      className="accounting-numeric p-1 text-right font-body text-[9pt]"
                      style={
                        d.sectionColor != 'OOUTSOURCE'
                          ? { color: d.sectionColor }
                          : { color: window.settings.tenant.outsourceColor }
                      }>
                      {d.forecastRate.toLocaleString('ja-JP', {
                        style: 'percent',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={9}>
                  <hr className="my-0.5 h-px border-0 bg-gray-900" />
                </td>
              </tr>
              <tr>
                <td className="p-1 text-right text-[9pt]">合計 :</td>
                <td className="accounting-numeric p-1 text-right font-body text-[9pt]">
                  {forecastSumOfContractNum.toLocaleString('ja-JP', {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  <Trans>件</Trans>
                </td>
                <td className="accounting-numeric p-1 text-right font-body text-[9pt]">
                  {forecastSumOfBuildingNum.toLocaleString('ja-JP', {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  <Trans>件</Trans>
                </td>
                <td
                  className={clsx('accounting-numeric p-1 text-right font-body text-[9pt]', {
                    hidden: !window.settings.tenant.showCurrency || !window.permissions.includes('p_currency'),
                  })}>
                  {window.settings.tenant.showCurrency && window.permissions.includes('p_currency')
                    ? forecastSumOfAmount.toLocaleString('ja-JP', {
                        style: 'currency',
                        currency: 'JPY',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : null}
                </td>
                <td />
                <td className="accounting-numeric p-1 text-right font-body text-[9pt]">
                  {actualSumOfContractNum.toLocaleString('ja-JP', {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  <Trans>件</Trans>
                </td>
                <td className="accounting-numeric p-1 text-right font-body text-[9pt]">
                  {actualSumOfBuildingNum.toLocaleString('ja-JP', {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  <Trans>件</Trans>
                </td>
                <td
                  className={clsx('accounting-numeric p-1 text-right font-body text-[9pt]', {
                    hidden: !window.settings.tenant.showCurrency || !window.permissions.includes('p_currency'),
                  })}>
                  {window.settings.tenant.showCurrency && window.permissions.includes('p_currency')
                    ? actualSumOfAmount.toLocaleString('ja-JP', {
                        style: 'currency',
                        currency: 'JPY',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : null}
                </td>
                <td className="accounting-numeric p-1 text-right font-body text-[9pt]">
                  {forecastRate.toLocaleString('ja-JP', {
                    style: 'percent',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
              </tr>
            </tfoot>
          </table>
        )}
      </div>
    </div>
  );
};

export default MonthlyPortlet;
