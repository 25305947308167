import { FocusEventHandler, ReactNode, memo, useEffect, useMemo, useState } from 'react';

import { ActionIcon, Popover } from '@mantine/core';
import { IconCircleX } from '@tabler/icons-react';

import { clsx } from 'clsx';

import { RawRegistrationButton } from '~/components/ui/aspi/Buttons2';

import TimePickerPanel from './TimePickerPanel';
import { publish } from '~/utils/event';

export const stringToTimeBlock = (time?: string | null, unit?: number | null): number => {
  if (time == null || time.length === 0 || unit == null || unit == 0 || Number.isNaN(unit)) {
    return NaN;
  }
  let block: number = NaN;
  if (time.indexOf(':') > 0) {
    const arr = time.split(':');
    if (arr.length == 2) {
      const hour = parseInt(arr[0]);
      const minutes = parseInt(arr[1]);
      block = Math.ceil((hour * 60 + minutes) / unit);
    }
  }

  return block;
};
export const timeBlockToString = (block?: number | null, unit?: number | null): string | null => {
  if (block == null || unit == null || block < 0 || unit <= 0 || Number.isNaN(block) || Number.isNaN(unit)) {
    return null;
  }
  const seconds = block * unit * 60;
  const hours = Math.trunc(seconds / 3600);
  const minutes = (seconds % 3600) / 60;

  return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
};

type TimePickerProps = {
  value?: string | null;
  onChange?: (value: string | null) => void;
  error?: ReactNode | null;
  className?: string;
  disabled?: boolean;
  min?: string | null;
  max?: string | null;
  length?: number | null;
  isEnd?: boolean;
  startTimeOfDay?: string;
  minutesPerBlock?: number;
  workStartTime: string;
  onClose?: () => void;
};

const Index = (props: TimePickerProps) => {
  const {
    startTimeOfDay = '00:00',
    minutesPerBlock = 15,
    value: _fromValue = null,
    onChange: _fromOnChange = () => {},
    error: _fromError,
    className = '',
    disabled = false,
    min: _min = null,
    max: _max = null,
    length: _length = null,
    isEnd = false,
    workStartTime = '09:00',
    onClose = () => {},
  } = props;

  const [opened, setOpened] = useState(false);
  const step = minutesPerBlock;

  const offset = stringToTimeBlock(startTimeOfDay, step);
  const offs = Number.parseInt(startTimeOfDay.split(':')[0]);

  const hourArray = useMemo(() => Array.from({ length: isEnd ? 25 : 24 }, (_, i) => offs - 1 + i + 1), [offset]);
  const minuteArray = useMemo(() => Array.from({ length: 60 / step }, (_, i) => i * step), [step]);

  const [fromBlock, setFromBlock] = useState(stringToTimeBlock(_fromValue, step));
  let fromValue = useMemo(() => timeBlockToString(fromBlock, step), [fromBlock, step]);
  useEffect(() => {
    fromValue = timeBlockToString(fromBlock, step);
  }, [fromBlock, step]);

  const min = useMemo(() => {
    let l = 0;
    if (_length != null && !Number.isNaN(_length) && _length > 0) {
      l = Math.trunc((_length * 60) / step);
    }
    let v = stringToTimeBlock(_min, step);
    if (Number.isNaN(v)) {
      v = offset;
    }

    return v + l + (isEnd ? 1 : 0);
  }, [_min, _length, step, offset, isEnd]);
  const max = useMemo(() => {
    let l = 0;
    if (_length != null && !Number.isNaN(_length) && _length > 0) {
      l = Math.ceil((_length * 60) / step);
    }
    let v = stringToTimeBlock(_max, step);
    if (Number.isNaN(v)) {
      v = offset + (24 * 60) / step;
    }

    return v - l;
  }, [_max, _length, step, offset]);

  const workStartBlock = stringToTimeBlock(workStartTime, step);
  const noonStartBlock = stringToTimeBlock('13:00', step);

  useEffect(() => {
    if (_fromValue == null || _fromValue.length == 0) {
      // if (opened) {
      //   setFromBlock(workStartBlock);
      // } else {
      setFromBlock(NaN);
      // }
    } else {
      setFromBlock(stringToTimeBlock(_fromValue, step));
    }
  }, [_fromValue, opened]);

  return (
    <Popover
      withArrow
      zIndex={1000}
      arrowPosition="side"
      arrowSize={10}
      radius="md"
      width={180}
      position="left"
      shadow="md"
      disabled={disabled}
      opened={opened}
      onChange={setOpened}
      onClose={onClose}>
      <Popover.Target>
        <div className="flex flex-row items-start justify-start gap-2">
          <input
            readOnly
            className={clsx(className, {
              'border-gray-300 bg-gray-100 text-gray-500': disabled,
              'border-gray-300 bg-white text-gray-900': !disabled && _fromError == null,
              'border-red-300 bg-white text-red-500': !disabled && _fromError != null,
            })}
            type="text"
            value={fromValue ?? ''}
            disabled={disabled}
            onClick={() => setOpened(o => !o)}
          />
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <div className="flex w-full flex-col items-start justify-start gap-2 text-[9pt]">
          {/*<div className="w-full flex flex-row items-center justify-between gap-0">*/}
          {/*<div className="flex flex-row items-center justify-start gap-1">*/}
          {/*  <input*/}
          {/*    readOnly*/}
          {/*    className="text-[10.5pt] p-2 w-[4rem] h-8 border rounded border-gray-300 text-gray-900"*/}
          {/*    type="text"*/}
          {/*    value={fromValue ?? ''}*/}
          {/*    disabled={disabled}*/}
          {/*  />*/}
          {/*  <ActionIcon className="bg-transparent border-0 text-gray-600" */}
          {/*    className=""*/}
          {/*    onClick={() => {*/}
          {/*      setFromBlock(NaN);*/}
          {/*    }}>*/}
          {/*    <IconCircleX size={14} />*/}
          {/*  </ActionIcon>*/}
          {/*</div>*/}
          {/*</div>*/}

          <div className="flex flex-row items-start justify-start gap-2">
            <TimePickerPanel
              offset={offset}
              step={step}
              block={fromBlock}
              hourArray={hourArray}
              minuteArray={minuteArray}
              min={min}
              max={max}
              isEnd={isEnd}
              fromValue={fromValue}
              workStartBlock={workStartBlock}
              noonStartBlock={noonStartBlock}
              onChange={block => {
                setFromBlock(block);
                if (_fromOnChange) {
                  _fromOnChange(timeBlockToString(block, step));
                }
              }}
            />
          </div>
          <div className="flex w-full flex-row items-start justify-between gap-2">
            <ActionIcon
              className="border-0 bg-transparent text-gray-600"
              onClick={() => {
                setFromBlock(NaN);
                _fromOnChange(null);
              }}>
              <IconCircleX size={14} />
            </ActionIcon>
            <RawRegistrationButton
              className="h-6 p-2 text-[8pt] font-bold"
              onClick={() => {
                publish('soundButtonClicked');
                setOpened(false);
              }}>
              閉じる
            </RawRegistrationButton>
          </div>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};
Index.displayName = 'TimeRangePicker';
export default memo(Index);
