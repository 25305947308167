import { axiosInstance as axios } from '~/axios';

const context = new AudioContext();

const loadSound = async (url: string) => {
  const response = await axios.get<Blob>(url, { responseType: 'blob' });

  return await context.decodeAudioData(await response.data.arrayBuffer());
};

export const playSound = (buffer?: AudioBuffer) => {
  if (buffer != null) {
    const source = context.createBufferSource(); // creates a sound source
    source.buffer = buffer; // tell the source which sound to play
    source.connect(context.destination); // connect the source to the context's destination (the speakers)
    const gainNode = context.createGain(); // Create a gain node
    source.connect(gainNode); // Connect the source to the gain node
    gainNode.connect(context.destination); // Connect the gain node to the destination
    gainNode.gain.value = 1; // Set the volume
    source.start(0); // play the source at the deisred time 0=now
  }
};

type SoundBuffers = {
  buttonClicked?: AudioBuffer;
  completed?: AudioBuffer;
  conditionBatchRegistration?: AudioBuffer;
  conditionYearmonthBatch?: AudioBuffer;
  extracted?: AudioBuffer;
  posterDataCreated?: AudioBuffer;
  posterBatchMailSend?: AudioBuffer;
  workplanCompleted?: AudioBuffer;
  workplanExcelOut?: AudioBuffer;
  workplanOutput?: AudioBuffer;
};

export const soundBuffers: SoundBuffers = {};

void loadSound('/sounds/button-clicked.mp3').then((buffer: AudioBuffer) => {
  soundBuffers.buttonClicked = buffer;
});
void loadSound('/sounds/completed.mp3').then((buffer: AudioBuffer) => {
  soundBuffers.completed = buffer;
});
void loadSound('/sounds/condition-batch-registration.mp3').then((buffer: AudioBuffer) => {
  soundBuffers.conditionBatchRegistration = buffer;
});
void loadSound('/sounds/condition-yearmonth-batch.mp3').then((buffer: AudioBuffer) => {
  soundBuffers.conditionYearmonthBatch = buffer;
});
void loadSound('/sounds/extracted.mp3').then((buffer: AudioBuffer) => {
  soundBuffers.extracted = buffer;
});
void loadSound('/sounds/poster-data-created.mp3').then((buffer: AudioBuffer) => {
  soundBuffers.posterDataCreated = buffer;
});
void loadSound('/sounds/poster-batch-mail-send.mp3').then((buffer: AudioBuffer) => {
  soundBuffers.posterBatchMailSend = buffer;
});
void loadSound('/sounds/workplan-completed.mp3').then((buffer: AudioBuffer) => {
  soundBuffers.workplanCompleted = buffer;
});
void loadSound('/sounds/workplan-excel-out.mp3').then((buffer: AudioBuffer) => {
  soundBuffers.workplanExcelOut = buffer;
});
void loadSound('/sounds/workplan-output.mp3').then((buffer: AudioBuffer) => {
  soundBuffers.workplanOutput = buffer;
});
