import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit={10}
    clipRule="evenodd"
    viewBox="0 0 556 612"
    {...props}>
    <path
      fill="#fcfdfe"
      d="M205.016 532.004c-8.556-.231-17.112-.462-25.886-1.462-.217-16.712-.217-32.656-.217-48.961h-6.017c-20.166-.001-40.333-.073-60.498.029-8.711.044-15.964-2.691-21.378-9.76-4.119-5.377-4.61-11.604-4.61-18.019.013-111.83.006-223.661.03-335.492.001-2.825.12-5.677.517-8.469 1.331-9.379 9.441-17.554 18.803-18.893 3.118-.446 6.309-.534 9.467-.536 71.832-.028 143.663-.022 215.495-.021 9.5 0 19 .059 28.499-.01 8.554-.064 15.576 2.932 20.829 9.836 3.981 5.232 4.539 11.291 4.537 17.542-.012 50.665-.007 101.331-.006 151.996v5.978h49.068c3.386-.125 4.543 1.442 4.423 4.846-.239 6.794-.074 13.603-.543 20.955-62.417.563-124.368.554-186.318.626-6.485.008-12.995.217-19.449.816-14.835 1.378-27.129 15.094-27.07 29.777.068 16.787.098 33.574.123 50.361.073 49.62.135 99.24.201 148.861m-66.412-351.987c5.613 1.191 11.221 3.39 16.839 3.416 54.947.259 109.895.162 164.843.138a67.727 67.727 0 0 0 7.465-.434c15.354-1.713 25.071-16.517 20.583-31.293-3.473-11.434-12.717-17.42-27.126-17.425-55.447-.02-110.895-.027-166.342.037-3.464.005-7.054.206-10.368 1.111-9.303 2.54-15.353 8.803-17.281 18.16-2.226 10.8 1.556 19.621 11.387 26.29m76.896 52.395c-16.814.151-33.663-.315-50.431.638-14.868.845-24.569 15.993-20.622 30.394 3.147 11.483 12.723 18.098 26.642 18.125 31.314.06 62.627.025 93.941.001 1.824-.001 3.655-.197 5.471-.399 12.744-1.42 22.02-11.642 21.994-24.201-.026-12.594-9.236-23.415-22.06-23.988-17.951-.802-35.954-.436-54.935-.57m-82.51 142.076c4.245 2.467 8.327 6.639 12.767 7.064 10.829 1.037 21.823.338 32.966.338v-48.202c-9.888 0-19.508-.271-29.106.075-9.892.357-17.09 5.555-20.937 14.502-3.914 9.103-2.6 17.884 4.31 26.223z"
    />
    <path
      fill="#4573c4"
      d="M205.202 532.314c-.252-49.931-.314-99.551-.387-149.171-.025-16.787-.055-33.574-.123-50.361-.059-14.683 12.235-28.399 27.07-29.777 6.454-.599 12.964-.808 19.449-.816 61.95-.072 123.901-.063 186.313-.161 13.954.05 27.447.167 40.94.32 13.513.154 24.664 4.97 30.523 17.716 2.349 5.109 3.427 11.207 3.513 16.88.372 24.319.158 48.648.158 72.974 0 37.161-.017 74.322.009 111.483.009 13.862-4.507 25.473-17.68 31.546-5.243 2.418-11.542 3.543-17.365 3.559-79.487.225-158.975.182-238.463.127-17.79-.012-28.463-7.747-33.957-24.319m69.107-99.123-21.194-21.22c0 3.034-.003 4.487.001 5.94.067 27.995.244 55.99.095 83.984-.024 4.635 1.305 5.81 5.86 5.8 66.321-.142 132.643-.095 198.964-.11 1.751-.001 3.503-.188 5.056-.277v-93.987l-1.316-.807c-1.087 1.472-2.008 3.108-3.284 4.391a36575.51 36575.51 0 0 1-69.537 69.737c-18.436 18.432-42.773 18.466-61.157.108-17.687-17.662-35.327-35.371-53.488-53.559M410.5 351.848H263.483l94.669 94.108 94.182-93.746c.124.09-.117-.238-.359-.239-13.492-.058-26.983-.089-41.475-.123z"
    />
    <path
      fill="#4573c4"
      d="M433.645 275.31c-16.124.452-32.252.452-49.064.452v-5.978c-.001-50.665-.006-101.331.006-151.996.002-6.251-.556-12.31-4.537-17.542-5.253-6.904-12.275-9.9-20.829-9.837-9.499.07-18.999.01-28.499.01-71.832 0-143.663-.006-215.495.022-3.158.002-6.349.09-9.467.536-9.362 1.34-17.472 9.514-18.803 18.893-.397 2.792-.516 5.644-.516 8.469-.025 111.831-.018 223.662-.03 335.492-.001 6.415.49 12.642 4.609 18.019 5.414 7.069 12.667 9.804 21.378 9.76 20.165-.102 40.332-.03 60.498-.029h6.017c0 16.305 0 32.249-.076 48.631-22.076.441-44.076.53-66.075.427-33.79-.158-61.312-18.9-71.24-49.428-2.73-8.396-3.99-17.635-4.01-26.492-.26-112.499-.208-224.998-.15-337.497.02-36.715 21.613-65.322 55.677-73.54 6.567-1.585 13.51-2.233 20.281-2.245 81.499-.149 162.998-.145 244.498-.074 36.689.032 65.304 21.658 73.518 55.713 1.546 6.409 2.204 13.178 2.225 19.784.17 52.666.087 105.332.084 158.45z"
    />
    <g fill="#4774c5">
      <path d="M138.27 179.858c-9.497-6.51-13.279-15.331-11.053-26.131 1.928-9.357 7.978-15.62 17.281-18.16 3.314-.905 6.904-1.106 10.368-1.111 55.447-.064 110.895-.057 166.342-.037 14.409.005 23.653 5.991 27.126 17.425 4.488 14.776-5.229 29.58-20.583 31.293a67.727 67.727 0 0 1-7.465.434c-54.948.024-109.896.121-164.843-.138-5.618-.026-11.226-2.225-17.173-3.575zM216 232.412c18.481.134 36.484-.232 54.435.57 12.824.573 22.034 11.394 22.06 23.988.026 12.559-9.25 22.781-21.994 24.201-1.816.202-3.647.398-5.471.399-31.314.024-62.627.059-93.941-.001-13.919-.027-23.495-6.642-26.642-18.125-3.947-14.401 5.754-29.549 20.622-30.394 16.768-.953 33.617-.487 50.931-.638zM132.761 374.208c-6.681-8.059-7.995-16.84-4.081-25.943 3.847-8.947 11.045-14.145 20.937-14.502 9.598-.346 19.218-.075 29.106-.075v48.202c-11.143 0-22.137.699-32.966-.338-4.44-.425-8.522-4.597-12.996-7.344z" />
    </g>
    <path
      fill="#f9fbfd"
      d="M274.559 433.441c17.911 17.938 35.551 35.647 53.238 53.309 18.384 18.358 42.721 18.324 61.157-.108a36575.51 36575.51 0 0 0 69.537-69.737c1.276-1.283 2.197-2.919 3.284-4.391l1.316.807v93.987c-1.553.089-3.305.276-5.056.277-66.321.015-132.643-.032-198.964.11-4.555.01-5.884-1.165-5.86-5.8.149-27.994-.028-55.989-.095-83.984-.004-1.453-.001-2.906-.001-5.94l21.444 21.47z"
    />
    <path
      fill="#fdfdfe"
      d="M411 351.848c13.992.034 27.483.065 40.975.123.242.001.483.329.359.239l-94.182 93.746-94.669-94.108z"
    />
  </svg>
);
export default SvgComponent;
