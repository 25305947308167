import { useEffect, useMemo, useState } from 'react';
import { useNavigate, matchRoutes, useLocation } from 'react-router-dom';

import { Box, Collapse, Group, rem, ThemeIcon, UnstyledButton } from '@mantine/core';
import { IconCalendarStats, IconChevronRight } from '@tabler/icons-react';

import clsx from 'clsx';

import classes from './NavbarLinksGroup.module.css';

interface LinksGroupProps {
  icon: React.FC<any>;
  label: string;
  initiallyOpened?: boolean;
  links?: { label: string; link: string; disabled: boolean }[];
  onClick?: (link: string) => void;
}

export const LinksGroup = ({
  icon: Icon,
  label,
  initiallyOpened,
  links,
  onClick = (link: string) => {},
}: LinksGroupProps) => {
  const hasLinks = Array.isArray(links);
  const navigate = useNavigate();
  const location = useLocation();

  const mm = useMemo(() => {
    if (hasLinks) {
      const m = matchRoutes(
        links.map(l => ({ path: l.link })),
        location
      );

      return m != null && m.length > 0;
    }

    return false;
  }, [links, location]);

  const [opened, setOpened] = useState(initiallyOpened || mm);

  const items = (hasLinks ? links : []).map(l => {
    const m = matchRoutes([{ path: l.link }], location);
    const matched = m != null && m.length > 0;

    return (
      <div
        key={l.label}
        className="cursor-pointer select-none"
        onClick={() => {
          if (l.disabled) return;
          navigate(l.link);
          onClick(l.link);
        }}>
        <div
          className={clsx('hover:bg-indigo-50', classes.link, {
            'bg-indigo-100': !l.disabled && matched,
            'bg-gray-100': l.disabled,
          })}>
          {l.label}
        </div>
      </div>
    );
  });

  return (
    <>
      <UnstyledButton className={clsx('cursor-pointer', classes.control)} onClick={() => setOpened(o => !o)}>
        <Group justify="space-between" gap={0}>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <ThemeIcon variant="light" size={30}>
              <Icon style={{ width: rem(18), height: rem(18) }} />
            </ThemeIcon>
            <Box ml="md" className={clsx('select-none', { 'text-gray-500': !hasLinks })}>
              {label}
            </Box>
          </Box>
          {hasLinks && (
            <IconChevronRight
              className={classes.chevron}
              stroke={1.5}
              style={{
                width: rem(16),
                height: rem(16),
                transform: opened ? 'rotate(-90deg)' : 'none',
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  );
};

const mockdata = {
  label: 'Releases',
  icon: IconCalendarStats,
  links: [
    { label: 'Upcoming releases', link: '/', disabled: false },
    { label: 'Previous releases', link: '/', disabled: true },
    { label: 'Releases schedule', link: '/', disabled: true },
  ],
};

export const NavbarLinksGroup = () => (
  <Box mih={220} p="md">
    <LinksGroup {...mockdata} />
  </Box>
);
